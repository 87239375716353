import mixpanel from 'mixpanel-browser'

/**
 * Automatically bind Mixpanel tracking to elements with the "mp-click-track" class.
 * This function adds click event listeners to these elements and tracks events with Mixpanel.
 */
function MixPanelHrefAutoBind() {
    // Get all elements with the "mp-click-track" class
    const mpLinks = document.getElementsByClassName('mp-click-track')

    for (const mpLink of mpLinks) {
        mpLink.addEventListener('click', function (event) {
            event.preventDefault()

            // Initialize variables to store event data
            let mp_id = null
            let mp_source = null

            // Check if data attributes are present and set corresponding variables
            if (mpLink.getAttribute('data-mp-event-data-id') != null) mp_id = mpLink.getAttribute('data-mp-event-data-id')
            if (mpLink.getAttribute('data-mp-event-source') != null) mp_source = mpLink.getAttribute('data-mp-event-source')

            // Get the event name from the element's data attribute
            let mp_eventname = mpLink.getAttribute('data-mp-event-name')

            // Track the event with Mixpanel using the collected data
            mixpanel.track(mp_eventname, MixPanelData(mp_id, mp_source), function () {
                // Handle additional actions, if specified
                if (mpLink.getAttribute('overlay-panel') != null)
                    document.getElementById(mpLink.getAttribute('overlay-panel')).style.display = 'block'
                if (mpLink.tagName === 'A' && mpLink.getAttribute('href')) window.location = mpLink.getAttribute('href')
            })
        })
    }
}

var mp_inited = false

if (!mp_inited) {
    console.log('MIXPANEL: INIT STARTED')

    mixpanel.init(process.env.MIXPANEL_KEY, {
        api_host: 'https://api-eu.mixpanel.com',
        ignore_dnt: true,
        track_pageview: false,
        debug: false,
        loaded: function () {
            console.log('MIXPANEL: INIT COMPLETED')
    
            var userKey = document.getElementById('mixpanel-binder').getAttribute('data-user-key')
    
            console.log(`MIXPANEL: USER KEY: ${userKey}`)
            
            var currentDistinctId = mixpanel.get_distinct_id()
            
            console.log(`MIXPANEL: DISTINCT ID: ${currentDistinctId}`)
    
            if (userKey === null || userKey === "")
                console.log('MIXPANEL: USER KEY = NULL → SKIPPING IDENTIFY')
            else if (currentDistinctId === userKey)
                console.log('MIXPANEL: DISTINCT ID = USER KEY → SKIPPING IDENTIFY')
            else {
                
                mixpanel.identify(userKey)
    
                console.log(`MIXPANEL: IDENTIFY: ${userKey}`)

            }

            mixpanel.track('$mp_web_page_view', {
                'current_url': window.location.href,
                'current_page_title': document.title
            });
            
            mp_inited = true
            
            MixPanelHrefAutoBind()
        },
    })
}

/**
 * Create a data object for Mixpanel event tracking with optional 'id' and 'source' properties.
 *
 * @param {string|null} id - The unique identifier (if available).
 * @param {string|null} source - The source of the event (if available).
 * @returns {Object} - An object containing 'id' and 'source' properties, if provided.
 */
export function MixPanelData(id, source) {
    // Initialize an empty object to hold the data
    var d = {}

    // Check if 'id' and 'source' are provided, and add them to the data object
    if (id != null) d.id = id
    if (source != null) d.source = source

    // Return the data object
    return d
}

/**
 * Assign the 'TrackMixPanelEvent' function to 'window.TrackMixPanelEvent'.
 * This makes it accessible globally for event tracking.
 */

/**
 * Track a Mixpanel event with the given name, source, and id.
 * This function is a wrapper for 'TrackMixPanelEventRaw' and ensures Mixpanel is initialized.
 *
 * @param {string} name - The name of the event to track.
 * @param {string|null} source - The source of the event (if available).
 * @param {string|null} id - The unique identifier (if available).
 */
export function TrackMixPanelEventRaw(name, source, id) {
    // Check if Mixpanel is initialized before tracking the event
    if (!mp_inited) return

    // Track the event with Mixpanel using the provided data
    mixpanel.track(name, MixPanelData(id, source))
}

/**
 * Track a Mixpanel event with the given name, source, and id.
 * This function provides an interface for tracking events and ensures Mixpanel is initialized.
 *
 * @param {string} name - The name of the event to track.
 * @param {string|null} source - The source of the event (if available).
 * @param {string|null} id - The unique identifier (if available).
 */
export function TrackMixPanelEvent(name, source, id) {
    TrackMixPanelEventRaw(name, source, id)
}

export function TrackMixPanelEventCustom({ name, properties }) {
    console.log('MIXPANEL: TRACK CUSTOM: ' + name)

    // Check if Mixpanel is initialized before tracking the event
    if (!mp_inited) {
        console.log('MIXPANEL: TRACK CUSTOM: MIXPANEL IS NOT INITED')

        return
    }

    try {
        mixpanel.track(name, properties)
    } catch (error) {
        console.error('MIXPANEL: TRACK CUSTOM ERROR: ', error)
    }
}

window.TrackMixPanelEvent = TrackMixPanelEvent
window.TrackMixPanelEventCustom = TrackMixPanelEventCustom

/**
 * Attach a form submission event listener to all search forms on the page.
 * If search forms exist, this function will enable tracking search queries.
 */
const searchForms = document.querySelectorAll('.search-form')

if (searchForms.length > 0) {
    for (const searchForm of searchForms) {
        searchForm.addEventListener('submit', (event) => {
            event.preventDefault()
            TrackMixPanelEventCustom({
                name: 'Chemical search',
                properties: {
                    'Search Query': searchForm.querySelector('.search-field').value || 'n/a',
                    'Element Location': searchForm.getAttribute('data-mp-element-location') || 'n/a',
                },
            })
            searchForm.submit()
        })
    }
}

// Focus event listener for search fields
const searchFields = document.querySelectorAll('.search-field')

if (searchFields.length > 0) {
    for (const searchField of searchFields) {
        searchField.addEventListener('focus', (event) => {
            TrackMixPanelEventCustom({
                name: 'Search field focus',
                properties: {
                    'Element Location': searchField.getAttribute('data-mp-element-location') || 'n/a',
                },
            })
        })
    }
}

let activeNavItem = null
const dropdownLinksMobile = document.querySelectorAll('.dropdown-link-mobile')
const dropdownLinks = document.querySelectorAll('.dropdown-link')
const navItems = document.querySelectorAll('.nav-item')
const footerLinks = document.querySelectorAll('.footer-link-mp')
const socialLinks = document.querySelectorAll('.social-link-mp')
const subscribeLinks = document.querySelectorAll('.subscribe-link-mp')

document.addEventListener('DOMContentLoaded', function () {
    if (dropdownLinks.length > 0) {
        dropdownLinks.forEach((link) => {
            link.addEventListener('click', function (event) {
                const parentNavItem = link.closest('.nav-item')
                const navTitleElement = parentNavItem ? parentNavItem.querySelector('.nav-link') : null
                const navTitle = navTitleElement ? navTitleElement.textContent.trim() : 'Unknown'

                const linkText = link.textContent.trim()
                const linkHref = link.getAttribute('href') || '#'
                const destinationURL = linkText === 'Blog' ? 'https://molport.com/blog' : process.env.SITE_URI + linkHref

                TrackMixPanelEventCustom({
                    name: 'Navigation Tab Clicked',
                    properties: {
                        'Tab Name': navTitle,
                        'Dropdown Item Clicked': 'Yes',
                        'Dropdown Item Name': linkText,
                        'Destination URL': destinationURL,
                    },
                })
            })
        })
    }

    navItems.forEach((item) => {
        item.addEventListener('click', function (event) {
            activeNavItem = item
        })
    })

    document.addEventListener('click', function (event) {
        if (activeNavItem && !activeNavItem.contains(event.target)) {
            const navTitleElement = activeNavItem.querySelector('.nav-link')
            const navTitle = navTitleElement ? navTitleElement.textContent.trim() : 'Unknown'

            TrackMixPanelEventCustom({
                name: 'Navigation Tab Clicked',
                properties: {
                    'Tab Name': navTitle,
                    'Dropdown Item Clicked': 'No',
                    'Dropdown Item Name': 'None',
                    'Destination URL': 'None',
                },
            })

            activeNavItem = null
        }
    })
})

navItems.forEach((item) => {
    item.addEventListener('click', function (event) {
        activeNavItem = item
    })
})

document.addEventListener('click', function (event) {
    if (activeNavItem && !activeNavItem.contains(event.target)) {
        const navTitleElement = activeNavItem.querySelector('.nav-link')
        const navTitle = navTitleElement ? navTitleElement.textContent.trim() : 'Unknown'

        TrackMixPanelEventCustom({
            name: 'Navigation Tab Clicked',
            properties: {
                'Tab Name': navTitle,
                'Dropdown Item Clicked': 'No',
                'Dropdown Item Name': 'None',
                'Destination URL': 'None',
            },
        })

        activeNavItem = null
    }
})

if (dropdownLinksMobile.length > 0) {
    dropdownLinksMobile.forEach((link) => {
        link.addEventListener('click', function (event) {
            const parentNavItem = link.closest('.nav-item')
            const navTitleElement = parentNavItem ? parentNavItem.querySelector('.nav-link') : null
            const navTitle = navTitleElement ? navTitleElement.textContent.trim() : 'Unknown'

            const linkText = link.textContent.trim()
            const linkHref = link.getAttribute('href') || '#'
            const destinationURL = linkText === 'Blog' ? 'https://molport.com/blog' : process.env.SITE_URI + linkHref

            TrackMixPanelEventCustom({
                name: 'Navigation Tab Clicked',
                properties: {
                    'Tab Name': navTitle,
                    'Dropdown Item Clicked': 'Yes',
                    'Dropdown Item Name': linkText,
                    'Destination URL': destinationURL,
                },
            })
        })
    })
}

if (footerLinks.length > 0) {
    footerLinks.forEach((link) => {
        link.addEventListener('click', function (event) {
            const linkText = link.textContent.trim()
            const linkHref = link.getAttribute('href') || '#'
            const destinationURL = linkText === 'Blog' ? 'https://molport.com/blog' : process.env.SITE_URI + linkHref

            TrackMixPanelEventCustom({
                name: 'Footer Link Clicked',
                properties: {
                    'Link Name': linkText,
                    'Destination URL': destinationURL,
                },
            })
        })
    })
}

if (subscribeLinks.length > 0) {
    subscribeLinks.forEach((link) => {
        link.addEventListener('click', function (event) {
            const linkText = link.textContent.trim()
            const linkHref = link.getAttribute('href') || '#'

            TrackMixPanelEventCustom({
                name: 'Footer Link Clicked',
                properties: {
                    'Link Name': linkText,
                    'Destination URL': linkHref,
                },
            })
        })
    })
}

if (socialLinks.length > 0) {
    socialLinks.forEach((link) => {
        link.addEventListener('click', function (event) {
            const linkText = link.textContent.trim()
            const linkHref = link.getAttribute('href') || '#'

            TrackMixPanelEventCustom({
                name: 'Footer Link Clicked',
                properties: {
                    'Link Name': linkText,
                    'Destination URL': linkHref,
                },
            })
        })
    })
}

const topCompoundLinks = document.querySelectorAll('.top-compound-link')

topCompoundLinks.forEach((link) => {
    link.addEventListener('click', function (event) {
        let interactionType = 'Top Compound Libraries'

        const destinationPageName = link.textContent.trim()
        const destinationPageURL = process.env.SITE_URI + link.getAttribute('href')
        const currentPageURL = window.location.href

        TrackMixPanelEventCustom({
            name: 'Search Dropdown Interaction',
            properties: {
                'Interaction Type': interactionType,
                'Destination Page Name': destinationPageName,
                'Destination URL': destinationPageURL,
            },
        })
    })
})

document.body.addEventListener('mousedown', (event) => {
    if (event.target && event.target.classList.contains('recent-searches-item')) {
        let interactionType = 'Recent Searches'
        const destinationPageName = 'Search for ' + event.target.textContent.trim()
        const currentPageURL = window.location.href

        TrackMixPanelEventCustom({
            name: 'Search Dropdown Interaction',
            properties: {
                'Interaction Type': interactionType,
                'Destination Page Name': destinationPageName,
            },
        })
    }
})
